<template>
	<div class="container" v-loading='loading'>
		<el-card body-style="padding:10px 20px">
			<div>
			  <span>为保障你商城的合规经营，我们为你生成了</span>
				<el-button type="text" @click="rulesshow=true">《{{rulesmsg.AgreementTitle}}》</el-button>
				<span>，当商城开启余额储值功能后，客户在商城小程序内发起充值时，需要先确认该协议。</span>
				<el-popover placement="bottom" width="250" trigger="hover">
					<div>
						<img :src="imgAPI+'/image/9ec6bb802b7017da741f776423c0b8e.png'" style="width:100%" alt="">
					</div>
					<el-button slot="reference" type="text">查看示例</el-button>
				</el-popover>
			</div>
		</el-card>
		<el-card class="box-card" style="margin-bottom: 100px;margin-top:10px">
			<div slot="header" class="clearfix">
				<span>余额充值</span>
				<span style="margin: 0 15px;">{{ruleForm.IsOpenMallTopUp ?'已开启':'已关闭'}}</span>
				<span style="color:#409EFF;cursor:pointer;" @click="ChangeOpen">{{ruleForm.IsOpenMallTopUp?'关闭':'开启'}}</span>
				<span style="color:#409EFF;cursor:pointer;margin-left: 15px;" @click="handleTablePromote" v-if="ruleForm.IsOpenMallTopUp">储值推广码</span>
			</div>
			<div class="text item">
				<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px">
					<el-form-item label-width="0px">
						<div style="display: flex;align-items:center;">
							<label style="margin-right: 15px;">充值金额:</label>
							<div style="margin-top: 20px;display: flex;justify-content: flex-start;flex-wrap: wrap;" class="elTags" v-if='ruleForm.TopUpPriceList.length'>
								<el-tag :key="index" v-for="(item,index) in ruleForm.TopUpPriceList" color="#DCDFE6" closable
								 :disable-transitions="false" style="width:150px;margin-bottom: 30px;margin-right:50px;color: #303133 !important;text-align: center;"
								 @close="handleClose(index)">
									<span class="tagStyle">￥{{item}}</span>
								</el-tag>
							</div>
							<el-popover placement="bottom" width="200" trigger="click" v-model="Numbervisible" @show='Showmodel'>
								<div style="display: flex;">
									<el-input v-model="EditPrice" style="width: 100px;margin-right: 10px;" @input='OnlyNumber(EditPrice,1)'></el-input>
									<el-button type='text' @click="SaveNumber(EditPrice)">保存</el-button>
									<el-button type='text' @click="Numbervisible=false;EditPrice=''">取消</el-button>
								</div>
								<el-button type="text" style="margin-left: 20px;" slot="reference">添加金额</el-button>
							</el-popover>
						</div>
					</el-form-item>
					<el-form-item label-width="0px">
						<label style="margin-right: 15px;">自定义充值:</label>
						<el-radio-group v-model="ruleForm.IsOpenCustomTopUp" @change="ChangeTopminMoney">
							<el-radio :label="true" :disabled="!ruleForm.IsOpenMallTopUp">开启</el-radio>
							<el-radio :label="false" :disabled="!ruleForm.IsOpenMallTopUp">关闭</el-radio>
						</el-radio-group>
					</el-form-item>
					<div style="display: flex;" v-if="ruleForm.IsOpenCustomTopUp">
						<label style="margin-right: 15px;">最低充值金额:</label>
						<el-form-item label-width="0px" prop="TopUpMinMoney" :rules="rules.TopUpMinMoney">
							<el-input type="text" style="width: 500px;" v-model="ruleForm.TopUpMinMoney" @input='OnlyNumber(ruleForm.TopUpMinMoney,2)'></el-input>
						</el-form-item>
					</div>
					<el-form-item label-width="0px" style="display: flex;">
						<label style="margin-right: 15px;">充值赠送礼包:</label>
						<el-radio-group v-model="ruleForm.IsOpenTopUpGift">
							<el-radio :label="true" :disabled="!ruleForm.IsOpenMallTopUp">开启</el-radio>
							<el-radio :label="false" :disabled="!ruleForm.IsOpenMallTopUp">关闭</el-radio>
						</el-radio-group>
						<div style="margin-left: 100px;" v-if="ruleForm.IsOpenTopUpGift">
							<el-button type="text" @click='SelectPackge' v-if="ruleForm.IsOpenTopUpGift">选择礼包</el-button>
							<div class="table-container" :class="{'is-error-button':ruleForm.IsOpenTopUpGift}" style="min-height: 30px;margin-bottom: 50px;">
								<el-table :data="ruleForm.papeMallTopUpConfigGiftInfoList" style="width: 1200px;" ref="productChose">
									<el-table-column label="礼包名称" width="200">
										<template slot-scope="scope">
											<div class="product-info">
												<div>
													<div>
														<pre style="white-space: pre-wrap;margin:0;font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif;">{{scope.row.Name}}</pre>
													</div>
													<span style="color:#E51C23;" v-if="scope.row.GiftStateValue == '已停用'">{{scope.row.GiftStateValue}}</span>
												</div>
											</div>
										</template>
									</el-table-column>
									<el-table-column prop="Stock" label="发放时间" width="300">
										<template slot-scope="scope">
											<div v-if="scope.row.IsPermanentActivity">永久有效</div>
											<div v-else>
												{{scope.row.StartTime}}至{{scope.row.EndTime}}
											</div>
										</template>
									</el-table-column>
									<el-table-column prop="Stock" label="库存"></el-table-column>
									<el-table-column label="赠送门槛" width="300">
										<template slot-scope="scope">
											<el-form-item inline-message :prop="'papeMallTopUpConfigGiftInfoList.' +scope.$index + '.ThresholdMoney'"
											 :rules='rules.ThresholdMoney' label-width='0'>
												<div style="display: flex;align-items: center;">
													<label style="padding:0 7px;background: #F5F5F5;font-size: 13px;">单笔充值</label>
													<el-input v-model="scope.row.ThresholdMoney" :disabled="!ruleForm.IsOpenMallTopUp" type="text" style="width:150px;"
													 @input="scope.row.ThresholdMoney=validateFloat(scope.row.ThresholdMoney)"></el-input>
												</div>
											</el-form-item>
										</template>
									</el-table-column>
									<el-table-column label="操作">
										<template slot-scope="scope">
											<span style="color:#409EFF;cursor:pointer;margin-right:10px;" @click='packageClick(scope.row)'>礼包内容</span>
											<span style="color:#F56C6C;cursor:pointer;" @click="deleteChose(scope.row.Id)">删除</span>
										</template>
									</el-table-column>
								</el-table>
								<el-pagination v-if="page.total" style="margin-top:20px;float:right;" @size-change="productSizeChange"
								 @current-change="productCurrentChange" :current-page="page.current" :page-sizes="[5, 10, 15, 20, 25]"
								 :page-size="page.size" layout="total, sizes, prev, pager, next, jumper" :total="page.total">
								</el-pagination>
							</div>
						</div>
					</el-form-item>

					<el-form-item label-width="0px">
						<div style="display: flex;">
							<label style="margin-right: 15px;">余额使用须知:</label>
							<div style="position: relative;">
								<el-input @input="descInput" :disabled="!ruleForm.IsOpenMallTopUp" type="textarea" :rows="6" resize="none"
								 style="width: 500px;" v-model="ruleForm.TopUpAgreementContent" placeholder="请输入余额使用须知,最多500字" maxlength="500"></el-input>
								<span style='position:absolute;bottom:0px;right:20px;'>{{txtVal}}/500</span>
							</div>
						</div>
					</el-form-item>

				</el-form>
			</div>
		</el-card>
		<div class="footer">
			<el-button style="width:240px;margin-left: 20px;" type="primary" @click="saveBalanceInfo('ruleForm')">保存</el-button>
		</div>
		<el-dialog title="选择充值礼包" :visible.sync="addPackageShow" width="1000px" class="dialog">
			<select-package :selectedData="selectedList" @getSelectList="getSelectList" v-if="addPackageShow"></select-package>
		</el-dialog>
		<el-dialog title="礼包内容" :visible.sync="PackageInfoShow" width="800px" class="dialog">
			<div style="margin-bottom: 15px;">{{packageName}}</div>
			<div class="table-container" style="min-height: 30px;margin-bottom: 50px;">
				<el-table :data="ConfigGiftInfoList" style="width: 100%;">
					<el-table-column prop="TypeValue" label="类型"></el-table-column>
					<el-table-column prop="Content" label="内容"></el-table-column>
				</el-table>
			</div>
		</el-dialog>

		<el-dialog :visible.sync="rulesshow" v-loading="rulesloading" @close="closeagreement">
			<div class = "dialogtitle">{{rulesmsg.AgreementTitle}}</div>
			<div v-if="rulesshow" class = "agrementcontent" ref="agreement" v-html="rulesmsg.AgreementContent"></div>
			<div style="margin-top:15px;text-align:center">
				<!-- <el-button type="primary" style="width:70%" @click="knowrules">我已知晓并同意{{rulesmsg.AgreementTitle}}</el-button> -->
				<el-button style="color:#303133;margin-top:15px" @click="rulesshow=false">关闭</el-button>
			</div>
		</el-dialog>

		<batch-cods :weiCodeVisivble="weiCodeShow" :weiCodeData="weiCodeData" :title='"储值推广码:"' @cancel="weiCodeCancel"></batch-cods>

	</div>
</template>

<script>
	import config from '@/config/index';
	import batchCods from "@/components/bathCodes"
	import {
		topUpConfigIndex,
		topUpConfigEdit,
		topUpConfigiftInfo,
		topUpConfigOpen
	} from '@/api/TurnTomySelf'
	import {
		mallsystemAgreementInfoInit
	} from '@/api/sv1.0.0.js'
	import selectPackage from './selectPackage'
	export default {
		components: {
			selectPackage,
			batchCods
		},
		data() {
			var checkDiscount = (rule, value, callback) => {
				let index1 = rule.fullField.split(".")[1];
				let index = (this.page.current - 1) * (this.page.size) + Number(index1)
				let value1 = this.ruleForm.MallTopUpConfigGiftInfoList[index].ThresholdMoney
				if (!value1 && value1 !== 0) {
					return callback(new Error('请设置充值金额'));
				} else if (Number(value1) > 20000 || Number(value1) < 0.01) {
					return callback(new Error('金额请输入在0.01~20000元之间'));
				} else {
					return callback();
				}
			};
			var checkTopUpMinMoney = (rule, value, callback) => {
				if (!value && value !== 0) {
					return callback(new Error('请设置最低充值金额'));
				} else if (Number(value) > 20000 || Number(value) < 0.01) {
					return callback(new Error('金额请输入在0.01~20000元之间'));
				} else {
					return callback();
				}
			};
			return {
				imgAPI:config.IMG_BASE,
				weiCodeShow: false,
				weiCodeData: {},
				packageName: '',
				ConfigGiftInfoList: [],
				PackageInfoShow: false,
				loading: false,
				txtVal: 0,
				// IsopenBalance: false,
				ruleForm: {
					IsOpenMallTopUp: false,
					TopUpPriceList: [],
					IsOpenCustomTopUp: false,
					TopUpMinMoney: 0.01,
					IsOpenTopUpGift: false,
					MallTopUpConfigGiftInfoList: [],
					TopUpAgreementContent: '',
					papeMallTopUpConfigGiftInfoList: [],
				},
				rules: {
					ThresholdMoney: [{
						required: true,
						trigger: 'blur',
						validator: checkDiscount
					}],
					TopUpMinMoney: [{
						required: true,
						trigger: 'blur',
						validator: checkTopUpMinMoney
					}]
				},
				// MoneyList: [],
				EditPrice: '',
				Numbervisible: false,
				addPackageShow: false,
				selectedList: [],
				page: {
					total: 0,
					size: 5,
					current: 1
				},
				hash: {},

				rulesmsg:{},
				rulesshow:false,
				rulesloading:false,
			}
		},
		created() {
			this.getrules()
			this.gettopUpConfigIndex()
		},
		methods: {
			closeagreement(){
				this.$refs.agreement.scrollTop = 0+'px'
			},
			async getrules(){
				try{
					let res = await mallsystemAgreementInfoInit({
						AgreementType:5
					})
					if(res.IsSuccess){
						this.rulesmsg = res.Result
					}
				}finally{
					//
				}
			},
			ChangeTopminMoney() {
				if (this.ruleForm.IsOpenCustomTopUp) {
					if (this.ruleForm.TopUpMinMoney == 0) {
						this.ruleForm.TopUpMinMoney = 0.01
					}
				}
			},
			weiCodeCancel() {
				this.weiCodeShow = false;
			},
			handleTablePromote() {
				this.weiCodeShow = true;
				this.weiCodeData = {
					Page: 'pageA/pages/balanceRecharge/balanceRecharge',
					Scene: 1
				}
			},
			async packageClick(record) {
				try {
					this.packageName = record.Name
					this.PackageInfoShow = true
					this.loading = true
					let data = {
						ActivityFullId: record.ActivityFullId
					}
					let result = await topUpConfigiftInfo(data)
					this.ConfigGiftInfoList = result.Result
				} catch (err) {
					this.loading = false
				} finally {
					this.loading = false
				}
			},
			//删除商品
			deleteChose(Id) {
				this.ruleForm.MallTopUpConfigGiftInfoList = this.ruleForm.MallTopUpConfigGiftInfoList.filter(t => {
					if (t.Id != Id) {
						return t
					}
				})
				if ((this.ruleForm.MallTopUpConfigGiftInfoList.length) % this.page.size == 0 && this.page.current > 1) {
					this.page.current -= 1;
				}
				this.paginationPro()
			},
			isRepeat(arr) {
				this.hash = {};
				for (var i in arr) {
					if (this.hash[arr[i]])
						return true;
					this.hash[arr[i]] = true;
				}

				return false;

			},
			async gettopUpConfigIndex() {
				try {
					this.loading = true
					let result = await topUpConfigIndex()
					if (result.IsSuccess) {
						this.ruleForm = result.Result
						this.ruleForm.MallTopUpConfigGiftInfoList.map(item => {
							item.Id = item.ActivityFullId
							return item
						})
						this.paginationPro()
						this.txtVal = this.ruleForm.TopUpAgreementContent.length
						if (this.ruleForm.IsOpenCustomTopUp) {
							if (this.ruleForm.TopUpMinMoney == 0) {
								this.ruleForm.TopUpMinMoney = 0.01
							}
						}

					}
				} catch (err) {

				} finally {
					this.loading = false
				}
			},
			saveBalanceInfo(ruleForm) {
				this.$refs[ruleForm].validate(async (valid) => {
					if (valid) {
						// this.loading = true
						try {
							if (this.ruleForm.TopUpPriceList.length == 0 && !this.ruleForm.IsOpenCustomTopUp) {
								this.$message({
									type: 'error',
									message: '请添加充值金额或开启自定义充值',
									showClose: true
								})
								return
							}
							if (this.ruleForm.IsOpenTopUpGift) {
								if (this.ruleForm.MallTopUpConfigGiftInfoList.length == 0) {
									this.$common.completeFormInformation('请至少选择1个礼包', 'is-error-button')
									return
								} else {
									let arr = []
									arr = this.ruleForm.MallTopUpConfigGiftInfoList.map(item => {
										return item.ThresholdMoney
									})
									this.isRepeat(arr)
									var keys = Object.keys(this.hash); // 获取所有的key值
									if (keys.length < arr.length && this.ruleForm.MallTopUpConfigGiftInfoList.length != 1) {
										this.$message({
											type: 'error',
											message: '充值礼包赠送门槛不可相同，请重新设置',
											showClose: true
										})
										return
									}
								}
							}
							this.loading = true
							let data = {
								TopUpPriceList: this.ruleForm.TopUpPriceList,
								IsOpenCustomTopUp: this.ruleForm.IsOpenCustomTopUp,
								TopUpMinMoney: this.ruleForm.TopUpMinMoney,
								IsOpenTopUpGift: this.ruleForm.IsOpenTopUpGift,
								TopUpAgreementContent: this.ruleForm.TopUpAgreementContent,
								MallTopUpConfigGiftInfoList: this.ruleForm.MallTopUpConfigGiftInfoList
							}
							let result = await topUpConfigEdit(data)
							if (result.IsSuccess) {
								this.$message({
									type: 'success',
									message: '保存成功',
									showClose: true
								})
								this.gettopUpConfigIndex()

							}
						} catch (e) {
							this.loading = false
						} finally {
							this.loading = false
						}
					} else {
						this.$common.completeFormInformation('请完善储值规则', 'is-error')
						return false;
					}
				})
			},
			descInput() {
				this.txtVal = this.ruleForm.TopUpAgreementContent.length;
			},
			// 商品弹窗切换显示条数
			productSizeChange(val) {
				this.page.size = val;
				this.paginationPro()
				// this.$nextTick(()=>{
				// 	this.$refs.ruleForm.validate();
				// })
			},
			productCurrentChange(val) {
				this.page.current = val;
				this.paginationPro()
				// this.$nextTick(()=>{
				// 	this.$refs.ruleForm.validate();
				// })
			},
			//商品分页
			paginationPro() {
				this.page.total = this.ruleForm.MallTopUpConfigGiftInfoList.length;
				let start = (this.page.current - 1) * this.page.size
				let end = start + this.page.size
				let pagination = this.ruleForm.MallTopUpConfigGiftInfoList.slice(start, end);
				this.ruleForm.papeMallTopUpConfigGiftInfoList = pagination
				// this.$nextTick(()=>{
				//   this.$refs.ruleForm.validate();
				// })
				this.$forceUpdate()
			},
			// 两位小数截取
			validateFloat(num) {
				if (num != '' && num.substr(0, 1) == '.') {
					num = "";
				}
				num = num.replace(/^0*(0\.|[1-9])/, '$1');
				num = num.replace(/[^\d.]/g, "");
				num = num.replace(/\.{2,}/g, ".");
				num = num.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".")
				num = num.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3')
				if (num.indexOf(".") < 0 && num != "") {
					if (num.substr(0, 1) == '0' && num.length == 2) {
						num = num.substr(1, num.length);
					}
				}
				return num
			},
			//点击确定
			// ,
			getSelectList(data) {
				data = data.map(t => {
					return {
						...t,
						ThresholdMoney: '',
						GiftStateValue: t.ActivityStateShow,
						ActivityFullId: t.Id
					}
				})
				let MallTopUpConfigGiftInfoList = JSON.parse(JSON.stringify(this.ruleForm.MallTopUpConfigGiftInfoList))
				if (this.ruleForm.MallTopUpConfigGiftInfoList.length) {
					let temp = this.ruleForm.MallTopUpConfigGiftInfoList.map(item => item.Id)
					let resArr = data.filter(item => !temp.includes(item.Id))
					MallTopUpConfigGiftInfoList = MallTopUpConfigGiftInfoList.concat(resArr)
					this.ruleForm.MallTopUpConfigGiftInfoList = JSON.parse(JSON.stringify(MallTopUpConfigGiftInfoList))
				} else {
					this.ruleForm.MallTopUpConfigGiftInfoList = JSON.parse(JSON.stringify(data))
				}
				// 筛选掉没有选择
				let ids = data.map(t => {
					return t.Id
				})
				this.ruleForm.MallTopUpConfigGiftInfoList = this.ruleForm.MallTopUpConfigGiftInfoList.filter((t, i) => {
					if (ids.indexOf(t.Id) >= 0) {
						return true
					}
				})
				this.addPackageShow = false
				this.paginationPro()
			},
			SelectPackge() {
				this.addPackageShow = true
				this.selectedList = this.ruleForm.MallTopUpConfigGiftInfoList
			},
			//开启、关闭
			async ChangeOpen() {
				try {
					this.ruleForm.IsOpenMallTopUp = !this.ruleForm.IsOpenMallTopUp
					let data = {
						IsOpenMallTopUp: this.ruleForm.IsOpenMallTopUp
					}
					let result = await topUpConfigOpen(data)
					if (result.IsSuccess) {
						if (this.ruleForm.IsOpenMallTopUp) {
							this.$message({
								showClose: true,
								type: 'success',
								message: '储值功能已开启'
							});
						} else {
							this.$message({
								showClose: true,
								type: 'success',
								message: '储值功能已关闭'
							});
						}
						this.gettopUpConfigIndex()
					}
				} catch (err) {

				} finally {

				}
			},
			handleClose(index) {
				this.ruleForm.TopUpPriceList.splice(index, 1);
				this.ruleForm.TopUpPriceList.sort((a, b) => {
					return a - b
				})
			},
			Showmodel() {
				this.EditPrice = ''
			},
			//保存金额
			SaveNumber(obj) {
				if (obj) {
					this.Numbervisible = false
					this.ruleForm.TopUpPriceList.push(Number(obj))
					this.ruleForm.TopUpPriceList.sort((a, b) => {
						return a - b
					})
				} else {

					this.$message({
						type: 'error',
						message: '请输入充值金额',
						showClose: true
					})
					return
				}

			},
			OnlyNumber(obj, index) {
				if (obj != '' && obj.substr(0, 1) == '.') {
					obj = "";
				}
				obj = obj.replace(/^0*(0\.|[1-9])/, '$1'); //粘贴不生效
				obj = obj.replace(/[^\d.]/g, ""); //清除“数字”和“.”以外的字符
				obj = obj.replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的
				obj = obj.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".")
				obj = obj.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3') //只能输入两个小数
				if (obj.indexOf(".") < 0 && obj != "") { //以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
					if (obj.substr(0, 1) == '0' && obj.length == 2) {
						obj = obj.substr(1, obj.length);
					}
				}

				if (index == 1) {
					this.EditPrice = obj
				} else {
					this.ruleForm.TopUpMinMoney = obj
				}
			},
		}
	}
</script>

<style lang="less" scoped>
	.container {
		background: #fff;
		padding: 10px;
		font-size: 14px;

		.footer {
			background-color: #fff;
			border-top: 1px solid #ebeef5;
			width: 100%;
			height: 80px;
			text-align: center;
			line-height: 80px;
			position: fixed;
			bottom: 0px;
			z-index: 10;
		}

		::v-deep .el-form-item__error {
			position: inherit !important;
			margin-top: 5px;
		}

		::v-deep .elTags {
			margin-top: 0px !important;
		}

		::v-deep .elTags .el-tag--medium {
			margin-bottom: 0px !important;
			margin-right: 20px !important;
		}

		::v-deep .elTags .el-tag--medium .el-icon-close {
			-webkit-transform: scale(1) !important;
			transform: scale(1) !important;
		}

		::v-deep .elTags .el-tag {
			width: 90px !important;
			display: flex;
			justify-content: center;
			height: 30px !important;
			align-items: center;
			position: relative;
		}

		::v-deep .elTags .el-tag .tagStyle {
			width: 100%;
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			white-space: normal;
			word-wrap: break-word;
			word-break: break-all;
		}

		::v-deep .elTags .el-tag .el-tag__close {
			position: absolute;
			color: #666 !important;
			top: -8px !important;
			color: #fff !important;
			background: #ddd !important;
			right: -5px !important;
		}

		.header {
			display: flex;
			align-items: center;
		}
	}
	.dialogtitle{
		display: flex;
		flex-direction: row;
		justify-content: center;
		font-size: 18px;
		/* font-weight: bold; */
		color: #303133;
	}
	.agrementcontent{
		max-height:500px;
		overflow:hidden;
		overflow-y:auto;
		/* border:1px solid black; */
		width: 95%;
		margin:0px auto;
		// white-space: pre;
		color: #606266;
		font-size: 14px;
	}
	.flexCol-center{
		display: flex;
		flex-direction: column;
		align-items: center;
	}
</style>

<template>
	<div class="container">
		<div class="filter-container title-border">
			<div class="filter-item">
				<label class="label" style='display: inline-block;width: 75px;'>关键字: </label>
				<el-input v-model="searchKey" style="width: 250px;" placeholder="礼包名称"></el-input>
			</div>
			<div class="filter-item">
				<el-button type="primary" style="margin-right:10px;width:90px;" @click="handleFilter">查询</el-button>
			</div>
		</div>
		<!-- @select-all='selectAll' -->
		<!-- table -->
		<div class="ishowloading" v-loading='ishowloading'>
			<div v-show="tableDataList.length">
				<div class="table-container content">
					<el-table :data="tableDataList" v-loading="loading" height="450" style="width: 100%" ref="compSelectProTable"
					 :row-key="getRowKeys" @selection-change="handleSelectionChange">
						<el-table-column type="selection" width="55" :reserve-selection="true">
						</el-table-column>
						<el-table-column prop="Name" label="礼包" width="200px">
							<template slot-scope="scope">
								<div>
									<pre style="white-space: pre-wrap;margin:0;font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif;">{{scope.row.Name}}</pre>
								</div>
							</template>
						</el-table-column>
						<el-table-column label="发放时间">
							<template slot-scope="scope">
								<div v-if="scope.row.IsPermanentActivity">永久有效</div>
								<div v-else>
									{{scope.row.StartTime}}至{{scope.row.EndTime}}
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="Stock" label="库存"></el-table-column>
					</el-table>
					<el-checkbox style="margin-top:25px;margin-left:13px;float:left;" :value="checkedAll" @change="selectCurrentAll">当前页全选</el-checkbox>
					<el-pagination v-if="page.total" style="margin-top:20px;float:right;" @size-change="handleSizeChange"
					 @current-change="handleCurrentChange" :current-page="page.current" :page-sizes="[5,10, 15, 20, 25, 30]"
					 :page-size="page.size" layout="total, sizes, prev, pager, next, jumper" :total="page.total">
					</el-pagination>
				</div>
				<div slot="footer" class="dialog-footer">
					已选中
					<span style="color:#F56C6C">{{multipleSelection.length}}</span>
					件
					<el-button class="button" @click="saveSelectProList" style="margin-left:10px;" type="primary">确定</el-button>
				</div>
			</div>
			<div v-show='!tableDataList.length' style='text-align: center;margin-top: 15px;'>
				<span>还未创建礼包，</span><span style="cursor: pointer;color:#409EFF;" @click="Createpackage">点击此处立马创建</span>
			</div>
		</div>
	</div>
</template>

<script>
	import config from '@/config/index'
	import {
		Giftpopuplist
	} from "@/api/TurnTomySelf"
	export default {
		name: 'selectPackage',
		props: {
			selectedData: { //需要选中的商品列表
				type: Array,
				default: () => {
					return []
				}
			},
		},
		computed: {
			checkedAll() {
				var val = this.multipleSelection;
				let ableSelectLength = 0 //获取当前页可选的全部页数
				var disabledLen = 0;
				var ids = val.map(item => {
					return item.Id
				})
				this.tableDataList.forEach(item => {
					if (ids.indexOf(item.Id) > -1 || item.IsDisabled) {
						ableSelectLength++;
					}
					if (item.IsDisabled) {
						disabledLen++;
					}
				});
				return (ableSelectLength > 0 && this.tableDataList.length == ableSelectLength && disabledLen != ableSelectLength) ?
					true : false;
			}
		},
		data() {
			return {
				goUrls: config.GO_URL,
				searchKey: '',
				page: {
					total: 0,
					current: 1,
					size: 10
				},
				tableDataList: [],
				multipleSelection: [],
				loading: false,
				isReady: false,
				ishowloading:false

			}
		},
		created() {
			this.ishowloading = true
			this.getSingleList()
		},
		methods: {
			//创建礼包
			Createpackage() {
				let headsUrls = process.env.NODE_ENV == 'production' ? 'index.html#' : ''
				let url = this.goUrls + headsUrls + '/Customer/storedPackage'
				window.open(url)
			},
			async getSingleList() {
				this.loading = true;
				try {
					let data = {
						Keywords: this.searchKey,
						ActivityState: 3,
						Skip: (this.page.current - 1) * this.page.size, // 略过数据
						Take: this.page.size, // 取的数据
					};
					let result = await Giftpopuplist(data)
					this.page.total = result.Result.Total;
					this.tableDataList = result.Result.Results;
					this.ishowloading = false
					
					console.log(this.ishowloading,'loading')

				} catch (error) {
					console.log(error);
				} finally {
					if (!this.isReady) {
						this.selectedData.map(item => {
							this.$refs['compSelectProTable'].toggleRowSelection(item, true);
						})
						this.isReady = true;
					}
					this.loading = false;
					// this.ishowloading = false
				}
			},
			selectCurrentAll(e) {
				// 
				this.$refs['compSelectProTable'].toggleAllSelection()
			},
			//当前页全选
			handleSelectionChange(val) {
				this.multipleSelection = val;
			},
			// 切换显示条数
			handleSizeChange(val) {
				this.page.size = val;
				this.getSingleList();
			},
			// 翻页
			handleCurrentChange(val) {
				this.page.current = val;
				this.getSingleList()
			},
			// selectInit(row) {
			// 		return !row.IsDisabled
			// },
			// clearSelection() {
			// 	this.$nextTick(() => {
			// 		this.$refs.compSelectProTable.clearSelection();
			// 	});
			// },
			getRowKeys(row) {
				return row.Id;
			},
			handleFilter() {
				this.page.current = 1;
				this.getSingleList();
			},
			//关闭弹框，以及曝光选择的列表
			saveSelectProList() {
				if (!this.multipleSelection.length) {
					this.$message({
						message: '请选择礼包',
						type: 'error'
					})
					return
				}
				this.$emit('getSelectList', this.multipleSelection)
			}

		}
	}
</script>

<style lang="less" scoped>
	.contanier {
		position: relative;
		overflow: hidden;
	}

	.content {
		overflow: hidden;
	}

	.dialog-footer {
		margin-top: 20px;
		text-align: center;

		.button {
			width: 150px;
		}
	}
</style>
